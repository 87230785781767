import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Box from '@material-ui/core/Box';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import SystemUpdateAltSharpIcon from '@material-ui/icons/SystemUpdateAltSharp';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import UpdateIcon from '@material-ui/icons/Update';
import CircleNotifications from '@material-ui/icons/Notifications';
import ClearIcon from '@material-ui/icons/Clear';
import Login from "./Login.js";
// import Register from "./Register.js";
//import Unregister from "./Unregister.js";
// import  formInstance  from "./CommandForms.js";
// import FormikContainer from "../containers/SendCommands/GetFDL";
import ResetHardwareID from "./ResetHardwareID.js"
import FraudUpdate from "./FraudUpdate.js"
import PushNotification from "./PushNotification.js"
import LiteAppPush from "./LiteAppPush.js"
import BannerPush from "./BannerPush.js"
import CMDsHistory from  "../containers/SendCommands/CMDsHistory";
import UserActivity from  "../containers/UserActivity";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export function ShowUserActivity() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <UserActivity/>
      </AppBar>
    </div>
  );
}

export function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(-1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          {/* <Tab disabled label="Commands" style={{ fontSize: 12 }} icon={<HowToRegIcon fontSize="large" style={{ fontSize: 30 }}/>} {...a11yProps(0)} />
          <Tab disabled label="Command Tracking" style={{ fontSize: 12 }} icon={<TrackChangesIcon fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(1)} /> */}
          <Tab title = "This component is used to clear your mobileID" label="Reset MobileID" style={{ fontSize: 12 , textDecorationLine: 'underline' ,  borderBottomWidth:1 }} icon={<UpdateIcon fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(0)} />
          <Tab title = "This component is used to optout the fraud devices/panelists from panel" label="OPTOUT FRAUD" style={{ fontSize: 12 ,textDecorationLine: 'underline' ,  borderBottomWidth: 1  }} icon={<ClearIcon fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(1)} />
          <Tab title = "This component is used to send notifications to panelists devices" label="Push Notification" style={{ fontSize: 12 , textDecorationLine: 'underline' ,  borderBottomWidth: 1  }} icon={<CircleNotifications fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(2)} />
          <Tab title = "This component is used for Lite APP Push to panelists devices" label="LITE APP" style={{ fontSize: 12 , textDecorationLine: 'underline' ,  borderBottomWidth: 1  }} icon={<CircleNotifications fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(3)} />
          <Tab title = "This component is used for Banner Push to panelists devices" label="BANNER PUSH" style={{ fontSize: 12 , textDecorationLine: 'underline' ,  borderBottomWidth: 1  }} icon={<CircleNotifications fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(4)} />
          {/* <Tab disabled label="FDL Download" style={{ fontSize: 12 }} icon={<InboxIcon fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(3)} />
          <Tab disabled label="EventLog Download" style={{ fontSize: 12 }} icon={<GetAppRoundedIcon fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(4)} />
          <Tab disabled label="HeartBeat Download" style={{ fontSize: 12 }} icon={<SystemUpdateAltSharpIcon fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(5)} />
          <Tab disabled label="Command History" style={{ fontSize: 12 }} icon={<TrackChangesIcon fontSize="large" style={{ fontSize: 30 }} />} {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
      {/* <TabPanel value={value} index={0}>
        <Commands />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CMDsTracking />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <ResetHardwareID />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FraudUpdate />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PushNotification />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <LiteAppPush />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BannerPush />
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        <DownloadFDL />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <DownloadEventlog />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DownloadHeartbeat />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <CMDsHistory />
      </TabPanel> */}
    </div>
  );
}